import React from "react";
import { Link } from "gatsby";

import Image from "components/UI/image";

import Main from "components/Layout/main";
import { useWindow } from "context/windowContext";

import "styles/faq-page.scss";

import searchIcon from 'images/svg/search-icon.svg'

const FAQCategory = (props) => {
    const { pageContext } = props;

    const gtmData = {
        page: {
            title: "FAQ category",
            type: "Static",
        },
    };

    return (
        <Main className="faq-wrapper" gtmData={gtmData}>
            <FaqCategoryWrapper pageContext={pageContext}/>
        </Main>
    );
};

const FaqCategoryWrapper = (props) => {
    const { pageContext } = props;
    const { name, parentCategoryLink, links, asideLinks } = pageContext;

    const subcategories = new Set();
    links.forEach((item) => subcategories.add(item.parentCategoryFaq));
    const subcategoriesArr = [...subcategories];

    const { document } = useWindow();
    const currentUrl = document?.location?.href;

    return (
        <div className="faq">
            <div className="faq__breadcrumbs">
                <span>
                    <Link className="faq__breadcrumbs--link" to="/faq">
                        Wellis
                    </Link>
                </span>
                <span> / </span>
                <span>{name}</span>
            </div>
            <div className="faq__heading">
                <h2 className="faq__heading--title">{name}</h2>
                <label className="faq__heading--search-wrapper">
                    <input
                        className="faq__heading--search"
                        type="text"
                        placeholder="Type keywords"
                    />
                    <Image className="search-icon" src={searchIcon}/>
                </label>
            </div>
            <div className="faq__category">
                <aside className="faq__category__aside">
                    {asideLinks.map((link) => (
                        <Link className={currentUrl.includes(link.href) ? "link--active" : "link"}
                              to={`/faq${link.href}`}>
                            {link.name}
                        </Link>
                    ))}
                </aside>
                <div className="faq__category__articles">
                    {subcategoriesArr.map((subcategory) => {
                        const subcategoryLinks = links.filter(
                            (link) => link.parentCategoryFaq === subcategory
                        );

                        return (
                            <>
                                <h3 className="subcategory__title">{subcategory}</h3>
                                <div>
                                    {subcategoryLinks.map((article) => (
                                        <div className="article">
                                            <Link to={`/faq${parentCategoryLink}${article.href}`}>
                                                <h4 className="article__title">{article.text}</h4>
                                            </Link>
                                            <p className="article__text">
                                                {article.description.description}
                                                <span>
                                                    <Link to={`/faq${parentCategoryLink}${article.href}`}
                                                          className="article__link">
                                                        {" "}continue reading
                                                    </Link>
                                                </span>
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default FAQCategory;
